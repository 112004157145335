import dotProp from 'dot-prop-immutable';

import {
    REDUCE_SECURITY_ELEMENT,
    REDUCE_SECURITY
} from '../../constants/actions/security';

const initialState = {
    email: null,
    password: null,
    reference: null,
    account: null,
    user: null
};

function contextReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_SECURITY_ELEMENT:
            return dotProp.set(state, action.payload.code, action.payload.value);
        case REDUCE_SECURITY:
            return (state = initialState);
        default:
            return state;
    }
}

export default contextReducer;
