export const GET_CLIENT_COMPANY = 'GET_CLIENT_COMPANY';
export const GET_CLIENT_COMPANY_BROKER = 'GET_CLIENT_COMPANY_BROKER';
export const REDUCE_CLIENT_COMPANY_BROKER = 'REDUCE_CLIENT_COMPANY_BROKER';
export const UPSERT_CLIENT_COMPANY = 'UPSERT_CLIENT_COMPANY';
export const REDUCE_CLIENT_COMPANY_ELEMENT = 'REDUCE_CLIENT_COMPANY_ELEMENT';
export const REDUCE_CLIENT_COMPANY = 'REDUCE_CLIENT_COMPANY';
export const GET_CLIENT_COMPANY_POLICIES = 'GET_CLIENT_COMPANY_POLICIES';
export const GET_CLIENT_COMPANY_POLICY = 'GET_CLIENT_COMPANY_POLICY';
export const GET_CLIENT_COMPANY_QUOTES = 'GET_CLIENT_COMPANY_QUOTES';
export const REDUCE_CLIENT_COMPANY_POLICIES = 'REDUCE_CLIENT_COMPANY_POLICIES';
export const REDUCE_CLIENT_COMPANY_POLICY = 'REDUCE_CLIENT_COMPANY_POLICY';
export const REDUCE_CLIENT_COMPANY_QUOTES = 'REDUCE_CLIENT_COMPANY_QUOTES';
export const QUERY_CLIENT_COMPANY_REFS = 'QUERY_CLIENT_COMPANY_REFS';
export const EXECUTE_CLIENT_COMPANY_ACTION = 'EXECUTE_CLIENT_COMPANY_ACTION';
export const REDUCE_QUERY_ELEMENT = 'REDUCE_CLIENT_COMPANY_QUERY_ELEMENT';
export const REDUCE_CLIENT_COMPANIES = 'REDUCE_CLIENT_COMPANIES';
export const REDUCE_ENTITY = 'REDUCE_ENTITY';
export const REDUCE_PARAMETERS = 'REDUCE_PARAMETERS';
export const REDUCE_PARAMETER = 'REDUCE_PARAMETER';
export const REDUCE_CLIENT_COMPANY_FNOL_ELEMENT = 'REDUCE_CLIENT_COMPANY_FNOL_ELEMENT';
export const GET_CLIENT_COMPANY_FNOL = 'GET_CLIENT_COMPANY_FNOL';
export const UPSERT_CLIENT_COMPANY_FNOL = 'UPSERT_CLIENT_COMPANY_FNOL';
export const REDUCE_CLIENT_COMPANY_FNOL = 'REDUCE_CLIENT_COMPANY_FNOL';
