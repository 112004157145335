import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';

import { Container } from 'metrix-common';

const Home = React.lazy(() => import('../../containers/Home'));
const Workflow = React.lazy(() => import('../../components/Workflow'));
const Quote = React.lazy(() => import('../../containers/Quote'));
const FourOhFour = React.lazy(() => import('../../containers/FourOhFour'));
const Redirect = React.lazy(() => import('../../containers/Redirect'));
const PrivacyPolicy = React.lazy(() => import('../../containers/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('../../containers/TermsOfService'));
const Login = React.lazy(() => import('../../containers/Login'));
const LoginClient = React.lazy(() => import('../../containers/LoginClient'));
const Dashboard = React.lazy(() => import('../../containers/Dashboard'));
const Product = React.lazy(() => import('../../containers/Product'));
const Embedded = React.lazy(() => import('../../containers/Embedded'));
const Summary = React.lazy(() => import('../../containers/Summary'));

const ExternalRedirectComponent = ({ url }) => {
    React.useEffect(() => {
        window.location.href = `${url}`;
    }, []);

    return null;
}

class Routes extends React.Component {
    render() {
        const extarnalClientWebUrl = `${this.props.context.configuration.clientWeb}/broker-company-signup`;

        return (
            <BrowserRouter>
                <Container {...this.props}>
                    <React.Suspense fallback={<div />}>
                        <Switch>
                            <Route exact path='/' render={(props) => <Home {...props} {...this.props} />} />
                            <Route exact path='/login' render={(props) => <Login {...props} {...this.props} />} />
                            <Route exact path='/client/login' render={(props) => <LoginClient {...props} {...this.props} />} />
                            <Route exact path="/broker-company-signup" render={(routeProps) => <ExternalRedirectComponent url={extarnalClientWebUrl} />} />
                            
                            <Route path='/dashboard' render={(props) => <Dashboard {...props} {...this.props} />} />
                            <Route exact path='/profile' render={(props) => <Summary {...props} {...this.props} />} />
                            <Route exact path='/workflow' render={(props) => <Workflow {...props} {...this.props} />} />
                            <Route exact path='/quote' render={(props) => <Quote {...props} {...this.props} />} />
                            
                            <Route exact path='/send-quote' render={(props) => 'send-quote page'} />
                            <Route exact path='/my-quotes' render={(props) => 'my-quotes page'} />
                            <Route exact path='/my-info' render={(props) => 'my-info page'} />
                            <Route exact path='/my-claims' render={(props) => 'my-claims page'} />
                            <Route exact path='/contact-my-broker' render={(props) => 'contact-my-broker page'} />
                            
                            <Route exact path='/privacy' render={(props) => <PrivacyPolicy {...props} {...this.props} />} />
                            <Route exact path='/terms' render={(props) => <TermsOfService {...props} {...this.props} />} />
                            <Route exact path='/redirect' render={(props) => <Redirect {...props} {...this.props} />} />
                            <Route exact path='/404' render={(props) => <FourOhFour {...props} {...this.props} />} />
                            
                            <Route exact path='/error' render={(props) => <FourOhFour type={'api_fail'} {...props} {...this.props} />} />
                            <Route path={'/@:agentName'} render={(props) => <Home {...props} {...this.props} />} />
                            <Route path={'/product/:productName/@:agentName'} render={(props) => <Product {...props} {...this.props} />} />
                            <Route path={'/product/:productName'} render={(props) => <Product {...props} {...this.props} />} />
                            <Route path='/embedded' render={(props) => <Embedded {...props} {...this.props} />} />
                            <Route render={props => <FourOhFour {...props} {...this.props} />} />
                        </Switch>
                    </React.Suspense>
                </Container>
            </BrowserRouter>
        );
    }
}

export default Routes;
